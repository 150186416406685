import * as React from "react"
import TransitionLink from 'gatsby-plugin-transition-link';
import Layout from '../components/Layout'
import SEO from "../components/seo"
import CollageBigComp from '../components/Collage';
import {ContBookOpenPagesComp} from '../components/ContBookOpen/openPages';

const PageAnteportada = () => {
  return (
    <Layout
      themeColor={'light'}
      clsOverflow={true}
    >
      <SEO
        title=""
        description=""
        works='georgina quintana'
        bolImage='1'
        ogImage={`cv_f.jpg`}
        twitterImage={`cv_t.jpg`}
      />

      <ContBookOpenPagesComp>
        <CollageBigComp />
      </ContBookOpenPagesComp>

    </Layout>
  )
}

export default PageAnteportada;
