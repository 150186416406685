import React from 'react';
import {ContBookOpenPages} from './styles';

export const ContBookOpenPagesComp = ({children}) => {

  return (
    <ContBookOpenPages>
      {children}
    </ContBookOpenPages>
  )
}
