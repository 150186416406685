import styled from 'styled-components';
import bookOpen from '../../images/book-open.png';
import bookOpenPages from '../../images/hoja-2.png';

export const ContBookOpen = styled.div`
  height: calc(100vh - 30px);
  background-image: url(${bookOpen});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  overflow: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 995px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 995px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 995px;    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 740px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 1230px;
  }
  @media(min-width: 1920px){
    width: 1444px;
  }
`
export const ContBookOpenPages = styled.div`
  height: calc(100vh - 30px);
  background-image: url(${bookOpenPages});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 995px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 995px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 995px;    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 934px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 1230px;
  }
  @media(min-width: 1920px){
    width: 1444px;
  }
`
